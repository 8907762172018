/* eslint-disable no-unused-vars */
import React, { FC, MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ifProp } from 'styled-tools';
import Parser, { domToReact } from 'html-react-parser';
import { getImageUrl, mq } from '$lib/helpers';
import { useGtm } from '$hooks';
import { useAppStore } from '~/store';
import { Text } from '../text';
import { Link } from '../link';

type RichTextStyleProps = {
    columnCount?: number;
    extraPadding?: boolean;
    backgroundColor?: string;
    textColor?: string;
    noPadding?: boolean;
    className?: string;
};

type RichTextProps = {
    text?: string;
    // Prevent images from making container larger than screen
    // Issue with modal mobile with wide images
    preventImageContainerStretch?: boolean;
} & RichTextStyleProps;

export const RichText: FC<RichTextProps> = React.memo((props: RichTextProps) => {
    const { text, preventImageContainerStretch, ...restProps } = props;
    const { trackMailtoClick, trackTelClick } = useGtm();
    const { isApp } = useAppStore();

    if (!text) {
        return <></>;
    }

    const interceptLinkClick: MouseEventHandler = (e): void => {
        const anchorElem = e.target as HTMLAnchorElement;

        if (!anchorElem || anchorElem.tagName !== 'A' || anchorElem.host !== window.location.host) {
            return;
        }

        if (anchorElem && anchorElem?.href?.includes('mailto:')) {
            trackMailtoClick(anchorElem.href.replace('mailto:', '').replace(/@.*$/, ''));
        }

        if (anchorElem && anchorElem?.href?.includes('tel:')) {
            trackTelClick(anchorElem.href.replace('tel:', ''));
        }
    };

    return (
        <RichTextContainer {...restProps} onClick={interceptLinkClick}>
            {Parser(text, {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                replace: (domNode: any) => {
                    // if (domNode instanceof Element && domNode.name === 'img') { // TODO replace when issues are fixed with import
                    if (domNode.name === 'img' && domNode.attribs?.src) {
                        const width = domNode.attribs?.width;
                        const height = domNode.attribs?.height;

                        return (
                            <img
                                src={getImageUrl(domNode.attribs?.src)}
                                title={domNode.attribs?.title}
                                alt={domNode.attribs?.alt}
                                width={width}
                                height={height}
                                // eslint-disable-next-line react/no-unknown-property
                                css={css`
                                    ${domNode.attribs?.style}
                                `}
                                style={preventImageContainerStretch ? { maxWidth: '100%', height: 'auto' } : {}}
                            />
                        );
                    }
                    if (domNode.name?.toLowerCase() === 'a' && isApp) {
                        // Change all richtext links in app to prevent people from navigating outside checkout
                        return <Text style={{ wordBreak: 'break-word' }}>{domToReact(domNode.children)}</Text>;
                    }
                    // Map pure anchor tags to the link component.
                    if (
                        domNode.name?.toLowerCase() === 'a' &&
                        !!domNode.children?.length &&
                        typeof domNode.children?.[0]?.data === 'string' &&
                        typeof domNode.children?.[0]?.children?.[0]?.data !== 'string'
                    ) {
                        const { style, ...attribs } = domNode.attribs;

                        return (
                            <Link
                                {...attribs}
                                css={css`
                                    ${style}
                                    word-break: break-word;
                                `}
                                themedLink
                                onClick={interceptLinkClick}
                            >
                                {domNode.children?.[0]?.data}
                            </Link>
                        );
                    }
                    if (
                        domNode.name?.toLowerCase() === 'a' &&
                        !!domNode.children?.length &&
                        typeof domNode.children?.[0]?.data !== 'string' &&
                        typeof domNode.children?.[0]?.children?.[0]?.data === 'string'
                    ) {
                        const { style, ...attribs } = domNode.attribs;

                        return (
                            <Link
                                {...attribs}
                                css={css`
                                    ${style}
                                    word-break: break-word;
                                `}
                                themedLink
                                onClick={interceptLinkClick}
                            >
                                {domNode.children?.[0]?.children?.[0]?.data}
                            </Link>
                        );
                    }
                },
            })}
        </RichTextContainer>
    );
});

const RichTextContainer = styled.div<RichTextStyleProps>(
    ({ theme, columnCount, backgroundColor, textColor }) => ({
        columnCount: (columnCount || 0) > 1 ? columnCount : 0,
        columnGap: theme.space[10],
        padding: theme.space[5],
        color: textColor,
        backgroundColor: backgroundColor,

        h2: {
            ...theme.mixins.useTextStyle('display2'),
            fontSize: 34,
            fontWeight: 700,
            lineHeight: '42px',
            [mq(0, 'sm')]: {
                fontSize: 28,
            },
        },
        h3: {
            ...theme.mixins.useTextStyle('display3'),
            fontSize: 28,
            [mq(0, 'sm')]: {
                fontSize: 24,
            },
        },

        'ul,ol': {
            marginBlockStart: '1rem',
            marginBlockEnd: '1rem',
            paddingInlineStart: theme.space[8],
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
        },
        ul: {
            listStyle: 'disc',
        },
        ol: {
            listStyle: 'number',
        },
        [mq(0, 'sm')]: {
            columnCount: 'auto',
        },
    }),
    ifProp('extraPadding', ({ theme }) => ({
        paddingLeft: theme.space[12],
        paddingRight: theme.space[12],
        [mq(0, 'sm')]: {
            paddingLeft: theme.space[5],
            paddingRight: theme.space[5],
        },
    })),
    ifProp('noPadding', {
        padding: 0,
    })
);
